import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';
export const prod: IPartnerAppSettings = {
  cdnBaseUrl: 'https://premier-fde-prod-e2ajcwe6fmbcgvh8.b01.azurefd.net/premier-fxp',
  apiBaseUrl: 'https://premier-traffic-manager-prod.trafficmanager.net/',
  apimUrl: 'https://professionalservices.microsoft.com/agreementmanagement/',
  apimSub: '9a0c5b42c369488e8af922e4117d1814',
  compassOneBaseUrl: 'https://compassone.microsoft.com',
  environment: 'prod',
  serviceEndPoints: [
    {
      clientId: 'fe3ecace-6616-4ead-adf0-46d9d92e6574',
      serviceEndPoint: 'https://premier-traffic-manager-prod.trafficmanager.net/api',
    },
  ]
};
